<template>
  <div>
    <div class="item-box">
      <btc-chart v-if="chartData" ref="chart" :key="data.value" :data="chartData" :unit="unit" :is-en-expire="isEnExpire" :title="data.label" @click.native="handleCharts"/>
    </div>
    <div class="item-box" style="padding: 30px;">
      <div class="selector-box">
        <div v-show="data.value==='中国香港'" class="selector-left">
          <selector :key="data.value+'1'" :items="type" @tab-item="handleTabType"/>
          <selector :key="data.value+'2'" :items="meatType" :disabled="isDisable" @tab-item="handleTabMeatType"/>
          <selector :key="data.value+'3'" :items="storage" :disabled="isDisable" @tab-item="handleTabStorage"/>
        </div>
        <datetime v-if="startDate&&endDate" :key="data.value" :start-date.sync="startDate" :end-date.sync="endDate" @tap-start="handleTapDate($event, 'StartDate')" @tap-end="handleTapDate($event, 'EndDate')"/>
      </div>
      <btc-table-header :data="data.value==='中国香港' ? header : header2"/>
      <btc-table :data="list">
        <template v-slot="{ row, index }">
          <btc-table-cell :data="row" :index="index">
            <template v-slot="{ cell, index }">
              <span v-if="cell.Destination==='Total'">*</span>
              <span v-else>{{ index }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.Destination }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <span>{{ cell.Volume }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <b-form-checkbox v-model="checkbox"
              :value="cell.Destination"
              :disabled="(checkbox.length === 1 && checkbox[0] === cell.Destination) || !isEnExpire || isLogin"
              plain
              @change="handleChangeCheckbox"/>
            </template>
          </btc-table-cell>
        </template>
      </btc-table>
    </div>
  </div>
</template>

<script>
import BtcTable from './components/table'
import BtcChart from './components/chart'
import Selector from './components/selector'
import Datetime from './components/datepicker'
import BtcTableHeader from './components/table/table-header'
import BtcTableCell from './components/table/table-cell'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'IndiaHongKong',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BtcTable,
    BtcChart,
    Selector,
    Datetime,
    BtcTableCell,
    BtcTableHeader
  },
  data () {
    return {
      unit: 'Unit: Ton',
      list: [],
      selected: [],
      selectLength: 0,
      checkbox: ['Total'],
      chartData: null,
      chartQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'en',
        IsFirst: false,
        Origin: '中国香港',
        Destination: 'Total',
        Type: '总计',
        Storage: '总计',
        Feeding: '总计',
        isEn: true
      },
      tableQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'en',
        IsFirst: false,
        Origin: '中国香港',
        Type: '总计',
        Storage: '总计',
        Feeding: '总计',
        StartDate: '',
        EndDate: '',
        isEn: true
      },
      type: [
        { label: 'Beef', value: '牛肉' },
        { label: 'Beef Offal', value: '牛杂' }
      ],
      meatType: [
        { label: 'Total', value: '总计' },
        { label: 'Bone-in', value: '带骨' },
        { label: 'Boneless', value: '去骨' }
      ],
      storage: [
        { label: 'Total', value: '总计' },
        { label: 'Frozen', value: '冷冻' },
        { label: 'Chilled', value: '冰鲜' }
      ],
      header: [
        { label: 'No.', icon: require('@/assets/image/data/order.png') },
        { label: 'Source of Origin', icon: require('@/assets/image/data/origin.png') },
        { label: 'Import Volume (t)', icon: require('@/assets/image/data/volumn2.png') },
        { label: 'Comparison', icon: require('@/assets/image/data/contrast.png') }
      ],
      header2: [
        { label: 'No.', icon: require('@/assets/image/data/order.png') },
        { label: 'Destination', icon: require('@/assets/image/data/destination.png') },
        { label: 'Import Volume (t)', icon: require('@/assets/image/data/volumn2.png') },
        { label: 'Comparison', icon: require('@/assets/image/data/contrast.png') }
      ],
      tempType: '',
      isDisable: false,
      startDate: '',
      endDate: ''
    }
  },
  watch: {
    'data.value' (nval, oval) {
      if (nval !== oval) {
        this.reset()
        this.isDisable = false
        this.tableQuery.Type = '总计'
        this.tableQuery.Storage = '总计'
        this.tableQuery.Feeding = '总计'
        this.getData(nval, (data) => {
          this.$refs.chart.refreshData(data)
        })
      }
    }
  },
  computed: {
    isLogin () {
      return this.$store.getters.token == null || this.$store.getters.token === ''
    },
    isEnExpire () {
      return this.getUserProfile(this.$t('message._btcdata_en'))
    }
  },
  mounted () {
    this.getData(this.data.value)
  },
  methods: {
    reset () {
      this.checkbox = ['Total']
      this.chartQuery.Destination = 'Total'
    },
    getChartData (fn) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBeefWebDataTwoChart', this.chartQuery).then(() => {
        this.chartData = this.$store.getters.dataChart
        this.startDate = this.chartData.tableEndDate
        this.endDate = this.chartData.tableEndDate
        this.tableQuery.StartDate = this.endDate.replace('-', '')
        this.tableQuery.EndDate = this.endDate.replace('-', '')
        if (fn) {
          fn(this.chartData)
        }

        this.getTableData()

        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getTableData (refresh) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetBeefWebDataTwoTable', this.tableQuery).then(() => {
        this.list = this.$store.getters.dataTable.tableData
        if (refresh) {
          this.checkbox = ['Total']
          this.chartQuery.Destination = 'Total'
          this.getChartData((data) => {
            this.$refs.chart.refreshData(data)
          })
        }
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getData (params, fn) {
      this.chartQuery.Origin = params
      this.tableQuery.Origin = params
      this.getChartData(fn)
    },
    handleTabType (item) {
      if (item.value === '牛杂') {
        this.isDisable = true
        this.chartQuery.Type = item.value
        this.tableQuery.Type = item.value
      } else {
        this.isDisable = false
        this.chartQuery.Type = this.tempType
        this.tableQuery.Type = this.tempType
      }
      // this.getTableData(true)
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      }, true)
    },
    handleTabMeatType (item) {
      this.tempType = item.value
      this.chartQuery.Type = item.value
      this.tableQuery.Type = item.value
      // this.getTableData(true)
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      }, true)
    },
    handleTabStorage (item) {
      this.chartQuery.Storage = item.value
      this.tableQuery.Storage = item.value
      // this.getTableData(true)
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      }, true)
    },
    handleChangeCheckbox (e) {
      this.selected = e
      this.selectLength = e.length
      if (this.selectLength === 0) {
        return
      }
      if (this.selectLength > 5) {
        e.shift()
      }
      this.chartQuery.Destination = e.join('|')
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      })
    },
    handleTapDate (date, type) {
      this.tableQuery[type] = date
      this.getTableData()
    },
    handleCharts () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    isLoginEnExpire () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  min-height: 420px;
  margin: 20px;
  padding-top: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

// 筛选
.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
</style>
